import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (window && environment.production) {
  window.console.log =
    window.console.warn =
    window.console.info =
      () => {
        // Don't log anything on prod
      };
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
